.food {
	float: left;
	min-width: 256px;
	max-width: 512px;
	min-height: 256px;
	max-height: 512px;
	flex: 1;
	display: flex;
	position: relative;
	margin-top: 25px;
	margin-right: 25px;
}

.food img {
	max-width: 100%;
}

.food button {
	position: absolute;
	right: 0;
	top: 0;
	color: #fff;
}

.food button span {
	padding: 10px 10px 0 0;
}

.food span.date {
	position: absolute;
	left: 0;
	bottom: 0;
	color: #fff;
	padding: 0 0 10px 10px;
}